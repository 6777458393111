.all-home-page-module .full-link-marketing {
  height: 991px;
  padding: 125px 0 48px;
  display: flex;
  background-color: #F7F8FB;
  justify-content: center;
  align-items: center;
}
.all-home-page-module .full-link-marketing .center-content {
  width: 717px;
  height: 717px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
}
.all-home-page-module .full-link-marketing .center-content .center-content-core {
  width: 196px;
  height: 196px;
  border-radius: 50%;
  background-image: url(https://zhb-admin2-qa-1258344703.cos.ap-guangzhou.myqcloud.com/fengye/f56b8d877469a3fb07d94f8e9dc0d277.png);
  background-size: contain;
  color: #1258E2;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.all-home-page-module .full-link-marketing .center-content-list-ouside {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  background-image: url(https://zhb-admin2-qa-1258344703.cos.ap-guangzhou.myqcloud.com/fengye/8826736e5b745dd3a9ecc65d452c6a28.png);
  background-size: contain;
}
.all-home-page-module .full-link-marketing .center-content-item {
  width: 196px;
  height: 196px;
  position: absolute;
  border-radius: 50%;
  line-height: 196px;
  background-image: url(https://zhb-admin2-qa-1258344703.cos.ap-guangzhou.myqcloud.com/fengye/f56b8d877469a3fb07d94f8e9dc0d277.png);
  background-size: contain;
  transition: all 500ms;
  z-index: 2;
}
.all-home-page-module .full-link-marketing .center-content-item .center-content-show {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: #030303;
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
}
.all-home-page-module .full-link-marketing .center-content-item .center-content-show .item-title {
  color: #296BEF;
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
}
.all-home-page-module .full-link-marketing .center-content-item:hover {
  transition: all 500ms;
  -webkit-transform: scale(1.2) !important;
          transform: scale(1.2) !important;
}
.all-home-page-module .full-link-marketing .center-content-item-inside {
  width: 111px;
  height: 44px;
  position: absolute;
  color: #fff;
}
.all-home-page-module .full-link-marketing .center-content-item-inside .center-content-show-inside {
  width: 111px;
  height: 44px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 90px;
  background-color: #296BEF;
  text-align: center;
  line-height: 44px;
}
