.all-home-page-module .module-solution {
  height: 996px;
  background: #edf2f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.all-home-page-module .module-solution .module-solution-title {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 50px;
  margin: 70px 0 25px 0;
}
.all-home-page-module .module-solution .module-solution-tabs {
  width: 1440px;
  flex: 1;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tabitem {
  margin: 0 35px;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tabbar::before {
  border-color: #DBDDE2;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tablist {
  display: flex;
  justify-content: center;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tablist .tea-tabs__tab {
  color: #4D5869;
  font-size: 20px;
  font-weight: 400;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tablist .tea-tabs__tab:hover {
  color: #296BEF;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tablist .tea-tabs__tab:hover::after {
  display: none;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tablist .tea-tabs__tab.is-active:hover::after {
  display: block;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tablist .tea-tabs__tab::after {
  border-bottom-color: #296BEF;
}
.all-home-page-module .module-solution .module-solution-tabs .tea-tabs__tablist .tea-tabs__tab.is-active {
  color: #296BEF;
  font-weight: 600;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-item-content-img {
  width: 720px;
  height: 468px;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-content-show {
  margin-left: 100px;
  width: 439px;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-content-show .content-title {
  color: #000;
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
  white-space: pre-wrap;
  transition: 1s ease;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-content-show .content-split {
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background-color: #E6E8ED;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-content-show .solution-content-show-module {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-content-show .solution-content-show-module .content-label {
  color: #333;
  font-size: 24px;
  font-weight: 400;
  position: relative;
  margin-bottom: 8px;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-content-show .solution-content-show-module .content-label:before {
  content: '';
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  background: #296BEF;
  border-radius: 50%;
  left: -18px;
  top: 15px;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-content .solution-content-show .solution-content-show-module .content-val {
  color: #4D5869;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}
.all-home-page-module .module-solution .module-solution-tabs .solution-item .solution-item-btn {
  width: 294.481px;
  height: 50px;
  background: #296BEF;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}
