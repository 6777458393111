.all-home-page-module .module-footer {
  height: 80px;
  background: #333;
  color: #d6d6d6;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}
.all-home-page-module .module-footer .url-show-module {
  text-align: center;
}
.all-home-page-module .module-footer .url-show-module .url-show-item {
  padding: 0 24px;
  color: #d6d6d6;
}
.all-home-page-module .module-footer .url-show-module .url-show-item:not(:last-child) {
  border-right: 1px solid #656565;
}
