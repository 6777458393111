@-webkit-keyframes toRightFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes toRightFadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.ai-lab-sidder-menu {
  -webkit-animation: toRightFadeIn 0.4s ease;
          animation: toRightFadeIn 0.4s ease;
  position: relative;
  overflow: auto;
  font-size: 14px;
  line-height: 22px;
  padding-top: 8px;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 70px;
  margin: 0 -10px;
}
.ai-lab-sidder-menu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  border-top: 1px solid #D0D9E9;
}
.ai-lab-sidder-menu::-webkit-scrollbar {
  width: 4px;
}
.ai-lab-sidder-menu::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.ai-lab-sidder-menu::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.4);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.ai-lab-sidder-menu__title {
  color: #030303;
  margin: 17px 0 13px 0;
}
.ai-lab-sidder-menu__link {
  color: #4D5869;
}
.ai-lab-sidder-menu__link__item {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
}
.ai-lab-sidder-menu__link__item::before {
  content: '';
  position: absolute;
  top: 18px;
  left: 0;
  height: 20px;
  border-radius: 6px;
  width: 2px;
  background: #296BEF;
  z-index: 1;
  transition: all 0.4s ease;
  opacity: 0;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
}
.ai-lab-sidder-menu__link__item .bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 4px 12px 0 rgba(65, 77, 96, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.72);
  opacity: 0;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s ease;
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
}
.ai-lab-sidder-menu__link__item .bg::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(65, 77, 96, 0.1);
}
.ai-lab-sidder-menu__link__item .bg::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4));
}
.ai-lab-sidder-menu__link__item > span {
  position: relative;
}
.ai-lab-sidder-menu__link__item > span.icon {
  line-height: 0;
  margin: 0 12px;
}
.ai-lab-sidder-menu__link.active {
  color: #296BEF;
}
.ai-lab-sidder-menu__link.active .ai-lab-sidder-menu__link__item::before {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
.ai-lab-sidder-menu__link.active .ai-lab-sidder-menu__link__item .bg {
  opacity: 1;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
.ai-lab-sidder-menu__go-home {
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 0;
  width: 100%;
  height: 56px;
  background: center / 100% url(https://zhb-admin2-qa-1258344703.cos.ap-guangzhou.myqcloud.com/fengye/7a9ea617e702c6b848b11e6e10298127.png);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #003A95;
}
.ai-lab-sidder-menu__go-home::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: center / 100% url(https://zhb-admin2-qa-1258344703.cos.ap-guangzhou.myqcloud.com/fengye/568920bb33a5bd0fc4a308413091e3f6.png);
  opacity: 0;
  transition: all 0.4s ease;
}
.ai-lab-sidder-menu__go-home:hover {
  color: #fff;
  box-shadow: 0 4px 12px 0 rgba(65, 77, 96, 0.1);
}
.ai-lab-sidder-menu__go-home:hover::before {
  opacity: 1;
}
.ai-lab-sidder-menu__go-home > i {
  display: block;
  position: relative;
  margin-left: 6px;
  font-size: 16px;
}
.ai-lab-sidder-menu__go-home > span {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
