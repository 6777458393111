.all-home-page-module .landing-page-experience {
  box-sizing: border-box;
  background-color: #F7F8FB;
  height: 907px;
  padding: 84px 0 109px 0;
}
.all-home-page-module .landing-page-experience .experience-title {
  color: #000;
  font-size: 28px;
  font-weight: 600;
  line-height: 50px;
  text-align: center;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content {
  height: 527px;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 48px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .experience-detail-show {
  height: 100%;
  background-repeat: no-repeat;
  background-size: auto 100% !important;
  transition: 1s ease;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .experience-detail-show .expeience-item {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  display: flex;
  align-items: center;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .experience-detail-show .expeience-item .check-icon {
  position: relative;
  width: 15px;
  height: 10px;
  margin-right: 15px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .experience-detail-show .expeience-item .check-icon::after {
  content: ' ';
  position: absolute;
  display: inline-block;
  width: 15px;
  height: 10px;
  border-width: 0 0 3px 3px;
  overflow: hidden;
  border-color: #fff;
  border-style: solid;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 0;
  top: -3px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .experience-detail-show .expeience-item .expeience-item-label {
  color: #fff;
  padding: 0 0 0 8px;
  font-weight: 400;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience {
  width: 707px;
  padding: 60px 76px 53px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .expeience-item svg {
  color: #296BEF;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .expeience-item .expeience-item-label {
  color: #030303;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .main-experience-label {
  color: #030303;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 15px;
  white-space: nowrap;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .discription-content {
  color: #030303;
  font-size: 20px;
  font-weight: 400;
  margin-top: 29px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .proportion-module {
  margin-top: 21px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .proportion-module .proportion-item {
  color: #4D5869;
  height: 71px;
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .proportion-module .proportion-item .proportion-val {
  color: #030303;
  font-size: 50px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 15px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .proportion-module .proportion-item:not(:last-child) {
  border-right: 1px solid #a8b2b5;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .consult-module {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .consult-module .consult-btn {
  width: 294.481px;
  height: 50px;
  color: #fff;
  background: #296BEF;
  font-size: 16px;
  font-weight: 400;
  border-color: #296BEF;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .check-icon::after {
  border-color: #296BEF !important;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .active-experience .active-detail-show {
  overflow: auto;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .sub-experience {
  width: 218px;
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  padding: 60px 0 0 45px;
}
.all-home-page-module .landing-page-experience .landing-page-experience-content .main-experience-label {
  margin-bottom: 15px;
}
