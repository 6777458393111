#root {
  height: 100%;
}
.all-home-page-module {
  height: 100%;
  color: #030303;
  overflow: scroll;
  --scroll-bar: 0;
}
.all-home-page-module::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.all-home-page-module .module-header {
  height: 602px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}
.all-home-page-module .module-header .video-box {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}
.all-home-page-module .module-header .module-header-content {
  width: 1440px;
  margin: 0 auto;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  position: relative;
}
.all-home-page-module .module-header .module-header-content .module-header-content-show {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.all-home-page-module .module-header .module-header-content .login-module {
  display: flex;
  justify-content: space-between;
  padding-top: 27.8px;
}
.all-home-page-module .module-header .module-header-content .login-module .logo-show {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.all-home-page-module .module-header .module-header-content .login-module .logo-show img {
  width: 25.8px;
  height: 28px;
  margin: 0 11px;
}
.all-home-page-module .module-header .module-header-content .login-module .login-btn {
  width: 68px;
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  border: none;
  background-color: transparent;
}
.all-home-page-module .module-header .module-header-content .login-module .register-btn {
  width: 110px;
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: #296BEF;
}
.all-home-page-module .module-header .module-header-content .header-center-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.all-home-page-module .module-header .module-header-content .header-center-content .header-main-title {
  font-size: 50px;
  font-weight: 600;
}
.all-home-page-module .module-header .module-header-content .header-center-content .header-sub-title {
  font-size: 20px;
  margin-top: 11px;
  font-weight: 400;
}
.all-home-page-module .module-header .module-header-content .header-center-content .go-to-use-btn {
  width: 283px;
  height: 56px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  background-color: #296BEF;
  margin-top: 64px;
  border-color: #296BEF;
}
.all-home-page-module .module-header .module-header-content .header-center-content .go-to-use-btn .btn-content {
  display: flex;
  justify-content: space-between;
}
.all-home-page-module .module-header .module-header-content .ability-show-module {
  width: 1440px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: -120px;
}
.all-home-page-module .module-header .module-header-content .ability-show-module .ability-show-item {
  flex: 1;
  height: 120px;
  display: flex;
  align-items: center;
  padding: 20px 25px;
  border-radius: 8px;
  border: 1px solid #FFF;
  background: linear-gradient(180deg, #E9EDF5 0%, #FFF 100%);
  box-shadow: 0px 10px 30px 0px rgba(144, 158, 179, 0.2);
}
.all-home-page-module .module-header .module-header-content .ability-show-module .ability-show-item .ability-show-item-img {
  width: 88px;
  height: 88px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top;
}
.all-home-page-module .module-header .module-header-content .ability-show-module .ability-show-item .ability-show-item-img.is-leave {
  -webkit-animation: leave 0.3s steps(24) forwards;
          animation: leave 0.3s steps(24) forwards;
}
.all-home-page-module .module-header .module-header-content .ability-show-module .ability-show-item .ability-show-item-img.is-enter {
  -webkit-animation: enter 0.3s steps(24) forwards;
          animation: enter 0.3s steps(24) forwards;
}
.all-home-page-module .module-header .module-header-content .ability-show-module .ability-show-item .ability-show-item-text {
  flex: 1;
  padding-left: 10px;
}
.all-home-page-module .module-header .module-header-content .ability-show-module .ability-show-item .ability-show-item-text .ability-show-item-title {
  font-size: 20px;
  font-weight: 600;
}
.all-home-page-module .module-header .module-header-content .ability-show-module .ability-show-item .ability-show-item-text .ability-show-item-content {
  color: #4D5869;
  font-size: 14px;
  font-weight: 400;
}
.all-home-page-module .module-conten-last {
  height: 410px;
  background-image: url(https://zhb-admin2-qa-1258344703.cos.ap-guangzhou.myqcloud.com/fengye/42f69f1922b8f8082e308bc0af4549c9.png);
  background-color: #296BEF;
  background-size: 100% 100%;
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  padding: 92px 0 92px 240px;
  line-height: 50px;
}
.all-home-page-module .module-conten-last .module-conten-2 {
  font-size: 28px;
  font-weight: 400;
  margin-top: 20px;
}
.all-home-page-module .module-conten-last .module-conten-last-btn {
  display: flex;
  gap: 17px;
  margin-top: 61px;
}
.all-home-page-module .module-conten-last .module-conten-last-btn .last-go-to-use-btn1 {
  width: 217px;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #296BEF;
  background: #FFF;
}
.all-home-page-module .module-conten-last .module-conten-last-btn .last-go-to-use-btn {
  color: #fff;
  background: #296BEF;
  width: 217px;
  height: 50px;
  border: 2px solid #FFF;
}
@media screen and (min-width: 1220px) and (max-width: 1550px) {
  .all-home-page-module .module-header-content,
  .all-home-page-module .module-solution-tabs {
    width: 100% !important;
    padding: 0 30px !important;
  }
  .all-home-page-module .module-header-content-show,
  .all-home-page-module .ability-show-module {
    width: calc(100% - 60px) !important;
  }
  .all-home-page-module .solution-item-content-img {
    width: 55% !important;
    height: auto !important;
  }
  .all-home-page-module .solution-content-show {
    flex: 1 !important;
    margin-left: 50px !important;
  }
}
@media screen and (min-width: 1220px) and (max-width: 1292px) {
  .all-home-page-module .ability-show-module .ability-show-item {
    padding: 20px 20px !important;
  }
  .all-home-page-module .ability-show-module .ability-show-item .ability-show-item-img {
    width: 80px !important;
    height: 80px !important;
  }
  .all-home-page-module .go-to-use-btn {
    margin-top: 35px !important;
  }
}
@-webkit-keyframes leave {
  0% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}
@keyframes leave {
  0% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}
@-webkit-keyframes enter {
  0% {
    background-position: top;
  }
  100% {
    background-position: bottom;
  }
}
@keyframes enter {
  0% {
    background-position: top;
  }
  100% {
    background-position: bottom;
  }
}
@media screen and (max-width: 1220px) {
  .all-home-page-module {
    width: 1580px !important;
    overflow-x: auto;
  }
}
